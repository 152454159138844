<div class="container">
	<h1>Impresszum</h1>
	<p>A {{host}} minden tartalma szerzői jogi védelem alatt áll. A honlapon elhelyezett szövegek a lapra való hivatkozással szabadon idézhetőek. A fotók és mozgóképek egészének, illetve azok részeinek felhasználásához azonban a BDR MÉDIA Kft. előzetes, írásbeli engedélye szükséges.</p>
	<br />

	<h3>Főszerkesztő:</h3>
	Bereczki-Csák Helga<br />
	<ng-container *ngIf="host === 'civishir.hu'">csak.helga&#64;civishir.hu<br /></ng-container>
	<br />

	<h3>Főszerkesztő-helyettes:</h3>
	Fogarasi Renáta<br />
	<ng-container *ngIf="host === 'civishir.hu'">fogarasi.renata&#64;civishir.hu<br /></ng-container>
	<br />

	<h3>Szerkesztők:</h3>
	Bereczki Attila<br />
	<ng-container *ngIf="host === 'civishir.hu'">bereczki.attila&#64;civishir.hu<br /></ng-container>
	<br />
	Ratalics László<br />
	<ng-container *ngIf="host === 'civishir.hu'">ratalics.laszlo&#64;civishir.hu<br /></ng-container>
	<br />
	Kovács Zsolt<br />
	<ng-container *ngIf="host === 'civishir.hu'">kovacs.zsolt&#64;civishir.hu<br /></ng-container>
	<br />
	Takács Tibor<br />
	<ng-container *ngIf="host === 'civishir.hu'">takacs.tibor&#64;civishir.hu<br /></ng-container>
	<br />

	<h3>Termékvezető:</h3>
	Csengeri Imre<br />
	csengeri.imre&#64;bdrmedia.hu<br />
	telefon: +36 20 376 00 73<br />
	<br />

	<h3>Marketing és értékesítés:</h3>
	Szabó Erzsébet<br />
	szabo.erzsebet&#64;bdrmedia.hu<br />
	telefon: +36 20 420 43 32<br />
	<br />

	<h3>A szerkesztőség elérhetőségei:</h3>
	E-mail: szerkesztoseg&#64;{{host}}<br />
	Telefon: 06 52 612 757<br />
	Levelezési cím: 4025 Debrecen, Hal köz 3/A., 2. emelet 5.<br />
	<br />
	<br />
	Kiadó: BDR MÉDIA Kft.<br />
	Kiadó székhelye: 4025 Debrecen, Hal köz 3/A., 2. emelet 5.<br />
	email: bdrmedia&#64;bdrmedia.hu<br />
	Kiadásért felelős: Bereczki Szabolcs<br />
	Tartalomért felelős: Bereczki-Csák Helga<br />
	Stratégiai vezető: Fülöp István
	<br />
	<br />

</div>