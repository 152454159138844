import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ads, AnswerCounts, CivisCafeItem, Configuration, GetHomeResponse, GetNewsRequest, NewsShortItem, NewsDetail, Topic, WeatherInfo, AdsItem, NewsItem } from './interfaces';
import { HttpParams } from '@angular/common/http';
import { BaseApiService } from './services/base.api.serive';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private apiService: BaseApiService
    ) { }

	get apiBaseUrl(): string {
		return this.apiService.apiBaseUrl;
	}

    setApiBaseUrl(apiBaseUrl: string): void {
        this.apiService.apiBaseUrl = apiBaseUrl;
    }

    init(host: string): Observable<{
        topics: Topic[],
        popularTags: string[],
        popularNews: NewsShortItem[],
        featuredNews: NewsShortItem[],
		latestNews: NewsShortItem[],
        siteStatus: string,
        sessionToken: string,
        topTags: string[],
        today: string,
        weatherInfo: WeatherInfo,
		headerAds: AdsItem | null,
		popupAds: AdsItem | null,
		footerHtml: string,
		headerHtml: string,
		floatingAds: AdsItem |null;
    }> {
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('host', host);
        return this.apiService.getWithHttpParams('init', httpParams);
    }

    readConfig(): Observable<Configuration> {
        return this.apiService.get('#assets/config.json');
    }

    getHome(previewGuid: string = null): Observable<GetHomeResponse> {
        let httpParams: HttpParams = new HttpParams();
        if (previewGuid !== null) {
            httpParams = httpParams.append('preview', previewGuid);
        }
        return this.apiService.getWithHttpParams('home', httpParams);
    }

    getNewsDetail(slug: string, previewGuid: string = null): Observable<{
        newsDetail: NewsDetail
    }> {
        let httpParams: HttpParams = new HttpParams();
        if (previewGuid !== null) {
            httpParams = httpParams.append('previewguid', previewGuid);
        }
        return this.apiService.getWithHttpParams('news/' + slug, httpParams);
    }

    getNews(getNewsRequest: GetNewsRequest): Observable<NewsItem[]> {
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('request', JSON.stringify(getNewsRequest));
        return this.apiService.getWithHttpParams('news', httpParams);
    }

    getAds(ids: number[]): Observable<{
        ads: Ads
    }> {
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('ids', ids.join(','));
        return this.apiService.getWithHttpParams('ads', httpParams);
    }

    adsClick(id: number): Observable<{}> {
        return this.apiService.patch('ads/' + id, {});
    }

    getCiviscafe(): Observable<{
        items: CivisCafeItem[]
    }> {
        return this.apiService.get('civiscafe');
    }

    answer(votingId: number, answerId: number): Observable<{
        answerCounts: AnswerCounts
    }> {
        return this.apiService.post('vote/' + votingId + '/' + answerId, {});
    }
}
